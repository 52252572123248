import React, { useEffect, useState } from 'react';
import { fetchEventsById } from './EventsSlice';
import './events.scss';
import { AppUser } from '../../../user/model/AppUser';
import { EventsDetailsProps } from './EventsDetails';
import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks';
import { defaultStaticRanges, defineds, formateDate } from '../../../common/DateRangeSelector/DefaultRanges';
import calenderIcon from '../../../../images/calendar-icon.svg';
import filterIcon from '../../../../images/filter-icon.svg';
import plusIcon from '../../../../images/plus-square.svg';
import searchIcon from '../../../../images/search-icon.svg';
import downIcon from '../../../../images/thinArrow.svg';
import trashIcon from '../../../../images/trash-01.svg';
import thinArrow from '../../../../images/thinArrow.svg';

import DateRangeSelector from '../../../common/DateRangeSelector/DateRangeSelector';
import { addDays, endOfDay, startOfDay } from 'date-fns';
import { useDetectClickOutside } from 'react-detect-click-outside';
import Loader from '../../../common/page-loader/ComponentLoader';
import NoData from '../../../dashboard/components/no-data/NoData';
import { ConvertTimeToLocal } from '../../../../utilities/CommonFunctions';

const EventItem: React.FC<{ event: EventsDetailsProps }> = ({ event }) => {
  return (
    <div className='item-container'>
      <div className='header-item'>{event.eventType ?? '-'}</div>
      <div className='header-item'>{event.reason ?? '-'}</div>
      <div className='header-item'>{event.description ?? '-'}</div>
      <div className='header-item'>{event.eventDate && ConvertTimeToLocal(event.eventDate)}</div>
      <div className='header-item'>{event.lastOccurance && ConvertTimeToLocal(event.lastOccurance)}</div>
      <div className='header-item'>{event.occurancesInDateRange ?? '-'}</div>
    </div>
  );
};

const Events: React.FC<any> = () => {
  const dispatch = useAppDispatch();
  const { events, loading } = useAppSelector((state) => state.events);
  const storedUserData = localStorage.getItem('loggedInUser');
  const storedUser: AppUser = storedUserData ? JSON.parse(storedUserData) : null;
  const [showFilterPopup, setShowFilterPopup] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const [uniqueEventType, setUniqueEventType] = useState([]);

  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate: startOfDay(addDays(new Date(), -6)),
    endDate: endOfDay(new Date()),
    key: 'selection',
  });

  const [filterObject, setFilterObject] = useState([
    {
      id: 0,
      eventType: '',
      contains: 'contains',
      Keyword: '',
    },
  ]);

  const [eventsData, setEventsData] = useState<EventsDetailsProps[]>(events);
  const selectedAsset = useAppSelector((state) => state.assetGroups);
  const containOptions = ["Contains", "Except"];
  const [isContainDropdownOpen, setIsContainDropdownOpen] = useState([false]);
  const [isEventTypeDropdownOpen, setIsEventTypeDropdownOpen] = useState([false]);


  useEffect(() => {
    const wellName = selectedAsset?.selectedAssetName ?? '';
    const dtStateTime = formateDate(selectedDateRange.startDate);
    const dtEndTime = formateDate(selectedDateRange.endDate);
    if (wellName === '' || storedUser?.id === '') return;
    dispatch(
      fetchEventsById({ userId: storedUser?.id, nodeId: wellName, dtStartTime: dtStateTime, dtEndTime: dtEndTime }),
    )
      .unwrap()
      .then((response: EventsDetailsProps[]) => {
        setEventsData(response);
      })
      .catch((error) => {
        console.error('Failed to fetch project tab details:', error);
      });
    setShowCalendar(false);
    
  }, [selectedAsset.selectedAssetId, selectedDateRange]);

  const handleSlideOutClick = (e: any) => {
    if (e.target.id === 'range-calendar-input') return;
    if (showCalendar) {
      setShowCalendar(!showCalendar);
    }
  };

  const handleFilterOutClick = (e: any) => {
    if (e.target.id === 'serachFilterValue' || e.target.id === 'filter-btn' || e.target.id === 'delete-btn' || e.target.id ==='dropdown-item') return;
    if (showFilterPopup) {
      setShowFilterPopup(false);

      setIsEventTypeDropdownOpen(prevArray => {
        const newArray = new Array(prevArray?.length).fill(false);
        return newArray
       })

       setIsContainDropdownOpen(prevArray => {
        const newArray = new Array(prevArray?.length).fill(false);
        return newArray; 
      });
    }
  };

  const ref = useDetectClickOutside({ onTriggered: handleSlideOutClick });
  const filterRef = useDetectClickOutside({ onTriggered: handleFilterOutClick });

  const handleDeleteRow = (id: number, filterIndex:number) => {
    const newFilterObject = filterObject?.filter((item) => item?.id !== id);
    setFilterObject(newFilterObject);

    setIsContainDropdownOpen(prevState => [
      ...prevState.slice(0, filterIndex),
      ...prevState.slice(filterIndex + 1)
    ]);
    setIsEventTypeDropdownOpen(prevState => [
      ...prevState.slice(0, filterIndex),
      ...prevState.slice(filterIndex + 1)
    ]);
  };

  const handleClick = () => {
    const newFilterObject = [...filterObject];
    const addRowObj = {
      id: newFilterObject?.length !== 0 ? newFilterObject[newFilterObject?.length - 1]?.id + 1 : 0,
      eventType: '',
      contains: 'contains',
      Keyword: '',
    };
    newFilterObject.push(addRowObj);
    setFilterObject(newFilterObject);

    setIsContainDropdownOpen(prevState => [...prevState, false]);
    setIsEventTypeDropdownOpen(prevState => [...prevState, false])

  };

  const handleEventTypeFilter = (value:string, id: number, type: string) => {
    const data = filterObject?.map((item) => {
      if (item?.id === id) {
        return {
          id: id,
          eventType: type === 'eventType' ? value : item?.eventType,
          contains: type === 'contains' ? value : item?.contains,
          Keyword: type === 'keyword' ? value : item?.Keyword,
        };
      }
      return item;
    });
    setFilterObject(data);

    if (type === 'eventType') {
      setIsEventTypeDropdownOpen(prevArray => {
        const newArray = new Array(prevArray?.length).fill(false);
        return newArray
       })
    }else if (type === 'contains') {
      setIsContainDropdownOpen(prevArray => {
        const newArray = new Array(prevArray?.length).fill(false);
        return newArray; 
      });
    }
  };

  const handleClearButton = () => {
    setFilterObject([
      {
        id: 0,
        eventType: '',
        contains: 'contains',
        Keyword: '',
      },
    ]);

    setEventsData(events);
    setShowFilterPopup(false);
  };

  const handleApplyButton = () => {
    const eventArr = filterObject?.map((item) => item.eventType.toLowerCase());
    const eventTypeFilteredData = events?.filter((item: any) => {
      const keywordFiltered = filterObject.some((filterData) => {
        const description = item?.description ?? '';
        if (item?.reason?.toLowerCase().includes(filterData.Keyword.toLowerCase())) {
          return filterData?.contains.toLowerCase() === 'contains' ? true : false;
        } else if (description?.toLowerCase().includes(filterData.Keyword.toLowerCase())) {
          return filterData?.contains.toLowerCase() === 'contains' ? true : false;
        } else if (item?.eventDate?.toLowerCase().includes(filterData.Keyword.toLowerCase())) {
          return filterData?.contains.toLowerCase() === 'contains' ? true : false;
        } else {
          return filterData?.contains.toLowerCase() === 'contains' ? false : true;
        }
      });
      const eventFiltered = eventArr.includes(item?.eventType.toLowerCase());
      return keywordFiltered && eventFiltered;
    });
    setEventsData(eventTypeFilteredData);
    setShowFilterPopup(false);
  };

  const toggleContainDropdown = (filterIndex:number) => {
    setIsContainDropdownOpen(prevArray => {
      const value = prevArray[filterIndex]
      const newArray = new Array(prevArray?.length).fill(false);
      newArray[filterIndex] =!value
      return newArray; 
    });
    setIsEventTypeDropdownOpen((prevArray => {
      const newArray = new Array(prevArray?.length).fill(false);
      return newArray; 
    }))

  }


  const toggleEventTypeDropdown = (filterIndex:number) => {
    setIsEventTypeDropdownOpen((prevArray => {
      const value = prevArray[filterIndex]
      const newArray = new Array(prevArray?.length).fill(false);
      newArray[filterIndex] =!value
      return newArray; 
    }))

    setIsContainDropdownOpen((prevArray => {
      const newArray = new Array(prevArray?.length).fill(false);
      return newArray; 
    }))

  }


  useEffect(() => {
    const uniqueData:any = events
    .map((item) => item.eventType)
    .filter((value, index, self) => self.indexOf(value) === index);
    setUniqueEventType(uniqueData)
  }, [events])

  const renderdateRange = () => {
    return (
      <div className='trends-container-date-range'>
        <div className='input-group'>
          <img src={calenderIcon} alt='calendar-icon' className='absolute top-3 left-3' />
          <input
            id='range-calendar-input'
            className='date-range-input'
            value={`${formateDate(selectedDateRange.startDate)} - ${formateDate(selectedDateRange.endDate)}`}
            onClick={() => setShowCalendar(!showCalendar)}
          />
        </div>
        <div
          ref={ref}
        >
          {showCalendar && (
            <DateRangeSelector setShowCalendar={setShowCalendar} setSelectedDateRange={setSelectedDateRange} staticRanges={defaultStaticRanges} minDate={defineds?.startOfLastYear} maxDate={defineds?.endOfToday} selectedDateRange={selectedDateRange} />
          )}
        </div>

        <div style={{ position: 'relative' }}>
          <div
            id='filter-btn'
            style={{
              border: showFilterPopup
                ? '1px solid var(--Dark-mode-400, #4A5463)'
                : '1px solid var(--Dark-mode-400, #4A5463)',
              background: showFilterPopup ? 'var(--Dark-mode-800, #001023)' : 'inherit',
            }}
            onClick={() => setShowFilterPopup(!showFilterPopup)}
          >
            <img src={filterIcon} alt='filterIcon' />
            Filter
            <img src={downIcon} className={!showFilterPopup ? '' : 'rotate-180'} alt='upIcon' />
          </div>

          {showFilterPopup && (
            <div className='filter-modal-container' ref={filterRef}>
              <div className='filter-modal-header'>Advanced filters</div>

              <div className='filter-modal-body'>
                <div className='body-container'>
                  <div className='max-height'>
                  {filterObject?.map((item,filterIndex) => {
                    return (
                      <>
                        <div className='body-input-container' key={item.id}>
                          <div className='filter-input-container'>
                            Event type
                            {/* <img src={searchIcon} alt='searchIcon' className='search-icon' />
                            <select
                              onChange={(e) => handleEventTypeFilter(e, item.id, 'eventType')}
                              className='filter-input'
                              placeholder='Search'
                            >
                              <option value=''>Search</option>
                              {eventsData?.map((event: EventsDetailsProps | any, index: number) => (
                                <option value={event?.eventType} key={index}>
                                  {event?.eventType}
                                </option>
                              ))}
                            </select> */}
                            <div className='asset_event__dropdown event_type_dropdown'>
                              <div className='asset_event__dropdown-input-container' onClick={()=>toggleEventTypeDropdown(filterIndex)}>
                                <img src={searchIcon} alt='search-icon' className='search_icon' />

                                <input type='text' className='asset_event__dropdown-input ' value={item?.eventType =='' ? "Search" : item?.eventType} readOnly />
                                <img
                                  src={thinArrow}
                                  alt={isEventTypeDropdownOpen[filterIndex] ? 'Up Arrow' : 'Down Arrow'}
                                  className={`arrow-icon ${isEventTypeDropdownOpen[filterIndex] ? 'rotate-180' : ''}`}
                                />
                              </div>
                              {isEventTypeDropdownOpen[filterIndex] &&(
                                <ul className='asset_event__dropdown-list'>
                                  {
                                    uniqueEventType?.length > 0 ?
                                    uniqueEventType.map((event: EventsDetailsProps | any, index: number) => (
                                        <li id='serachFilterValue' key={index} className='asset_event__dropdown-item' onClick={() => { handleEventTypeFilter(event, item.id, 'eventType') }}>
                                          {event}
                                        </li>
                                      ))
                                      :
                                      <li className='asset_event__dropdown-item' style={{textAlign:"center"}}>
                                        No data
                                      </li>
                                  }

                                </ul>
                              )}
                            </div>
                            {/* <input className='filter-input' placeholder='Search' /> */}
                            {/* <img src={downIcon} alt='downIcon' className='down-icon' /> */}
                          </div>
                          <div className='filter-input-custom'>
                         
                            <div className='asset_event__dropdown contain_dropdown'>
                              <div className='asset_event__dropdown-input-container' onClick={()=>toggleContainDropdown(filterIndex)}>
                                <input type='text' className='asset_event__dropdown-input' value={item?.contains == 'contains' ? 'Contains' : item?.contains } readOnly />
                                <img
                                  src={thinArrow}
                                  alt={isContainDropdownOpen[filterIndex] ? 'Up Arrow' : 'Down Arrow'}
                                  className={`arrow-icon ${isContainDropdownOpen[filterIndex] ? 'rotate-180' : ''}`}
                                />
                              </div>
                              {isContainDropdownOpen[filterIndex] && (
                                <ul className='asset_event__dropdown-list'>
                                  {containOptions?.map((option, index) => (
                                    <li key={index} onClick={() => handleEventTypeFilter(option, item.id, 'contains')} className='asset_event__dropdown-item' id="dropdown-item">
                                      {option}
                                    </li>
                                  ))}
                                </ul>
                              )}
                            </div>

                            {/* <input className='filter-input' placeholder='Contains' /> */}
                            {/* <img src={downIcon} alt='downIcon' className='down-icon' /> */}
                          </div>
                          <div className='filter-input-container'>
                            Keyword
                            <img src={searchIcon} alt='searchIcon' className='search-icon' />
                            <input
                              className='filter-input'
                              onChange={(e) => handleEventTypeFilter(e.target.value, item.id, 'keyword')}
                              placeholder='Search'
                              value={item?.Keyword =='' ? "" : item?.Keyword}
                            />
                            <img src={downIcon} alt='downIcon' className='down-icon' />
                          </div>

                          <div id='delete-btn'>
                            <button id='delete-btn' className='delete-btn' onClick={() => handleDeleteRow(item.id,filterIndex)}>
                              <img id='delete-btn' src={trashIcon} alt='trashIcon' />
                            </button>
                          </div>
                        </div>
                      </>
                    );
                  })}
                  </div>

                  <button className='filter-add-btn' onClick={handleClick}>
                    <img src={plusIcon} alt='plusIcon' />
                    Add filter row
                  </button>
                </div>
              </div>
              <div className='filter-modal-footer'>
                <button className='footer-btn' onClick={handleClearButton}>
                  Clear
                </button>
                <button className='footer-btn' onClick={handleApplyButton}>
                  Apply
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      <div
        style={{ position: 'relative' }}
        className={`details-container ${(showCalendar || showFilterPopup) && 'min-height'}`}
      >
       {loading ? (
        <div className='flex items-center justify-center w-100 loader-margin'>
          <Loader />
        </div>
   ) : 
  (
  <>
      {renderdateRange()}

      {
        eventsData?.length !== 0 ? (
          <>
            <div className='header-container'>
              <div className='header-item'>Event type</div>
              <div className='header-item'>Reason</div>
              <div className='header-item'>Description</div>
              <div className='header-item'>Date</div>
              <div className='header-item'>Last occurance</div>
              <div className='header-item'>Occurrences in date range</div>
            </div>
            {eventsData?.map((event: EventsDetailsProps, index: number) => <EventItem key={index} event={event} />)}
          </>
        ) : (
          <NoData heading='No data found' />
        )
      }
 </>
)}

      </div>
    </>
  );
};

export default Events;

